import React, { useState, useEffect } from "react";
import { makeStyles, FormControl, Select, MenuItem } from "@material-ui/core";

import { getAllTypes } from "../controllers";
const useStyles = makeStyles((theme) => ({
  root: {},
}));

export default function TypeSelector({ initialSelection = [], onChange }) {
  const classes = useStyles();
  const [types, setTypes] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);

  async function _loadTypes() {
    const types = await getAllTypes();
    setTypes(types);
  }

  function _getStyles(type, selection) {
    const selectionIds = selection.map(({ id }) => id);
    return {
      fontWeight: selectionIds.indexOf(type.id) === -1 ? 300 : 500,
    };
  }
  function handleChangeSelection(event) {
    const selectedItems = event.target.value;
    onChange(selectedItems);
    setSelectedTypes(selectedItems);
  }

  useEffect(() => {
    _loadTypes();
  }, []);

  useEffect(() => {
    setSelectedTypes(initialSelection);
  }, [initialSelection.length]);

  return (
    <div className={classes.root}>
      <FormControl variant="outlined" fullWidth>
        <Select
          multiple
          displayEmpty
          value={selectedTypes}
          onChange={handleChangeSelection}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <em>Choisissez un type</em>;
            }
            return selected.map(({ name }) => name).join(", ");
          }}
        >
          {types.map((type) => (
            <MenuItem
              key={type.id}
              value={type}
              style={_getStyles(type, selectedTypes)}
            >
              {type.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
