import React from "react";
import CreateMapForm from "../../features/map/CreateMapForm";
import MapsGrid from "../../features/map/MapsGrid";
import Space from "../../components/Space";

export default function MapsML() {
  return (
    <div>
      <MapsGrid />
      <CreateMapForm />
      <Space height={8} />
    </div>
  );
}
