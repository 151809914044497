import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { List, ListItem, Button, ListItemText } from "@material-ui/core";
import { Link } from "react-router-dom";

import { deleteAllType, getAllTypes, observeType } from "../controllers";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.whiteBackground,
    padding: theme.spacing(1),
    "& > *": {
      marginBottom: theme.spacing(2),
    },
  },
}));

export default function TypesCollection() {
  const classes = useStyles();

  const [types, setTypes] = useState([]);
  const [storeEvents, setStoreEvents] = useState(0);

  async function onLoad() {
    const types = await getAllTypes();
    setTypes(types);
  }
  useEffect(() => {
    onLoad();
    const subscription = observeType((message) =>
      setStoreEvents((storeEvents) => storeEvents + 1)
    );
    return () => subscription.unsubscribe();
  }, [storeEvents]);

  return (
    <div className={classes.root}>
      <List disablePadding>
        {types.map(({ id, name }) => {
          return (
            <ListItem key={id} button component={Link} to="/home" divider>
              <ListItemText>{name}</ListItemText>
            </ListItem>
          );
        })}
      </List>
      <Button
        variant="outlined"
        color="primary"
        fullWidth
        onClick={deleteAllType}
      >
        {"Supprimer tout"}
      </Button>
    </div>
  );
}
