import React, { useRef, useState } from "react";
import { makeStyles, TextField, Button } from "@material-ui/core";
import { createMap, uploadAttachment } from "../controllers";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    background: theme.palette.common.whiteBackground,
  },
  imageLoaderContainer: {
    border: `1px solid ${theme.palette.common.border}`,
    marginBottom: theme.spacing(2),
    position: "relative",
    height: "100vw",
    boxSizing: "border-box",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
  },
  uploadButtonContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(1),
    position: "absolute",
    bottom: 0,
    width: "100%",
  },
  fileInput: {
    opacity: 0.1,
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
  },
  createButtonContainer: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
}));

export default function CreateMapForm() {
  const classes = useStyles();

  const inputRef = useRef();

  const [file, setFile] = useState();
  const [url, setUrl] = useState();
  const [name, setName] = useState("");

  function validate() {
    return url;
  }
  function handleLoadClick() {
    inputRef.current.click();
  }

  function handleInputChange(e) {
    const files = e.target.files;
    if (files && files[0]) {
      const file = files[0];
      setFile(file);
      if (name === "") setName(file.name);
      const reader = new FileReader();
      reader.onload = function (e) {
        setUrl(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  }

  async function handleCreateButtonClick() {
    const _name = name;
    const _file = file;
    setName("");
    setUrl();
    setFile();
    try {
      const newMap = await createMap(_name, _file);
      //const newFile = await uploadAttachment(newMap.id, _file);
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <div className={classes.root}>
      <div
        className={classes.imageLoaderContainer}
        style={{
          backgroundImage: `url(${url})`,
        }}
      >
        <input
          onChange={handleInputChange}
          className={classes.fileInput}
          type="file"
          ref={inputRef}
        />
        <div className={classes.uploadButtonContainer}>
          <Button
            onClick={handleLoadClick}
            variant="contained"
            disableElevation
          >
            {url ? "Changer" : "Charger un fichier"}
          </Button>
        </div>
      </div>
      <TextField
        fullWidth
        variant="outlined"
        label="Nom du plan"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <div className={classes.createButtonContainer}>
        <Button
          variant="contained"
          color="secondary"
          fullWidth
          disabled={!validate()}
          onClick={handleCreateButtonClick}
        >
          Créer
        </Button>
      </div>
    </div>
  );
}
