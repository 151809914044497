import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  imageContainer: {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    height: "100%",
    width: "100%",
    //overflow: "auto",
  },
}));

export default function FileImage({ file }) {
  const classes = useStyles();

  const [url, setUrl] = useState();

  function convertFileToUrl() {
    const url = window.URL.createObjectURL(file);
    setUrl(url);
  }

  useEffect(() => {
    if (file) convertFileToUrl();
  }, []);

  return (
    <div
      className={classes.imageContainer}
      style={{
        backgroundImage: `url(${url})`,
      }}
    />
  );
}
