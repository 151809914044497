import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    height: (props) => theme.spacing(props.height),
  },
}));

export default function Space({ height }) {
  const classes = useStyles({ height });
  return <div className={classes.root}></div>;
}
