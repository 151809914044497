import React from "react";
import { useMediaQuery } from "@material-ui/core";

import DesktopLayout from "./DesktopLayout";
import MobileLayout from "./MobileLayout";

export default function Home() {
  const match = useMediaQuery((theme) => theme.breakpoints.up("md"));
  return <div>{match ? <DesktopLayout /> : <MobileLayout />}</div>;
}
