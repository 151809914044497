import React from "react";

import LoginForm from "../../features/auth/LoginForm";
import ProfileButton from "../../features/auth/ProfileButton";
export default function Test() {
  return (
    <div>
      <ProfileButton />
      <LoginForm />
    </div>
  );
}
