import React, { useState, createContext, useEffect } from "react";

// routes
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "../Routes";

// styles
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "../styles/theme";
import { CssBaseline } from "@material-ui/core";

// amplify
import Amplify from "./amplify";
import { Hub } from "@aws-amplify/core";
import Auth from "@aws-amplify/auth";

// files datastore
import { connect } from "../data/files-datastore";

// context
export const AppContext = createContext();

function App() {
  // auth
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currentAuthenticatedUser, setCurrentAuthenticatedUser] = useState();

  // connect to files-datastore
  connect();

  // auth event listeners
  Hub.listen("auth", async (data) => {
    switch (data.payload.event) {
      case "signIn":
        console.log("user signed in");
        setIsAuthenticated(true);
        const user = await _getUser();
        setCurrentAuthenticatedUser(user);
        break;
      case "signUp":
        console.log("user signed up");
        break;
      case "signOut":
        console.log("user signed out");
        break;
      case "signIn_failure":
        console.log("user sign in failed");
        break;
      case "configured":
        console.log("the Auth module is configured");
        break;
      default:
        break;
    }
  });

  async function onLoad() {
    const currentAuthenticatedUser = await _getUser();
    if (currentAuthenticatedUser) {
      setCurrentAuthenticatedUser(currentAuthenticatedUser);
      setIsAuthenticated(true);
    }
  }

  // on load
  useEffect(() => {
    onLoad();
  }, []);

  // helpers
  async function _getUser() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      return user.attributes.email;
    } catch (error) {
      console.log("error", error);
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppContext.Provider
        value={{
          isAuthenticated,
          setIsAuthenticated,
          currentAuthenticatedUser,
          setCurrentAuthenticatedUser,
        }}
      >
        <Router>
          <Routes />
        </Router>
      </AppContext.Provider>
    </ThemeProvider>
  );
}

export default App;
