import React, { useContext } from "react";
import { makeStyles, Typography, Divider, Button } from "@material-ui/core";
import { Link } from "react-router-dom";

import { AppContext } from "../../../app/App";
import LogoutButton from "../../../features/auth/LogoutButton";

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.common.whiteBackground,
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  description: {
    marginBottom: theme.spacing(2),
  },
}));

export default function UserCard() {
  const classes = useStyles();
  const { currentAuthenticatedUser, isAuthenticated } = useContext(AppContext);

  return (
    <div className={classes.root}>
      {isAuthenticated ? (
        <>
          <Typography variant="h6">Votre compte</Typography>
          <Typography style={{ textAlign: "center" }}>
            {currentAuthenticatedUser}
          </Typography>
          <Divider />
          <div className={classes.buttonContainer}>
            <LogoutButton
              variant="contained"
              color="secondary"
              disableElevation
            />
          </div>
        </>
      ) : (
        <div>
          <Typography className={classes.description}>
            Connectez vous à la plateforme CONSTRUCTION42.
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            component={Link}
            to="/login"
            fullWidth
          >
            Se connecter
          </Button>
        </div>
      )}
    </div>
  );
}
