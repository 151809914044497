import { openDB } from "idb";

const FILES_DATASTORE = "files-datastore";

const FILE_DO = "file";

if (!("indexedDB" in window)) {
  console.log("This browser doesn't support IndexedDB");
}

let _ds;

export const connect = () =>
  openDB(FILES_DATASTORE, 1, {
    upgrade(db) {
      if (!db.objectStoreNames.contains(FILE_DO)) {
        db.createObjectStore(FILE_DO, { keyPath: "id" });
      }
    },
  }).then((ds) => {
    console.log("connected to files-datastore");
    _ds = ds;
  });

export const getDs = () => {
  if (_ds) {
    return _ds;
  }
  throw Error("No database found");
};

export async function createFile({ id, name, file }) {
  const buffer = await _blobToArrayBuffer(file);
  if (!_ds) return console.log("not connected");
  const tx = _ds.transaction(FILE_DO, "readwrite");
  tx.objectStore(FILE_DO).put({ id, name, buffer });
  return tx.complete;
}

export async function getFileById({ id }) {
  const tx = _ds.transaction(FILE_DO, "readonly");
  const { buffer } = await tx.objectStore(FILE_DO).get(id);
  return _arrayBufferToBlob(buffer, "image/png");
}

// helpers

function _arrayBufferToBlob(buffer, type) {
  return new Blob([buffer], { type: type });
}
function _blobToArrayBuffer(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener("loadend", (e) => {
      resolve(reader.result);
    });
    reader.addEventListener("error", reject);
    reader.readAsArrayBuffer(blob);
  });
}
