import React from "react";

import UserCard from "./components/UserCard";

export default function ProfileML() {
  return (
    <div>
      <UserCard />
    </div>
  );
}
