import React from "react";
import { useMediaQuery } from "@material-ui/core";

import MapsML from "./MapsML";
import MapsDL from "./MapsDL";

export default function Maps() {
  const match = useMediaQuery((theme) => theme.breakpoints.up("md"));
  return <div>{match ? <MapsDL /> : <MapsML />}</div>;
}
