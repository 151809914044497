import React from "react";
import { Button } from "@material-ui/core";
import { Auth } from "@aws-amplify/auth";

export default function LogoutButton(props) {
  async function handleLogoutButtonClick() {
    await _signOut();
  }

  async function _signOut() {
    try {
      await Auth.signOut();
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }
  return (
    <Button {...props} onClick={handleLogoutButtonClick}>
      Se déconnecter
    </Button>
  );
}
