import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { List, ListItem, Button, ListItemText } from "@material-ui/core";
import { Link } from "react-router-dom";

import {
  deleteAllOrganisation,
  getAllOrganisations,
  observeOrganisation,
} from "../controllers";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.whiteBackground,
    padding: theme.spacing(1),
    "& > *": {
      marginBottom: theme.spacing(2),
    },
  },
}));

export default function OrganisationsCollection() {
  const classes = useStyles();

  const [organisations, setOrganisations] = useState([]);
  const [storeEvents, setStoreEvents] = useState(0);

  async function onLoad() {
    const organisations = await getAllOrganisations();
    setOrganisations(organisations);
  }
  useEffect(() => {
    onLoad();
    const subscription = observeOrganisation((message) =>
      setStoreEvents((storeEvents) => storeEvents + 1)
    );
    return () => subscription.unsubscribe();
  }, [storeEvents]);

  return (
    <div className={classes.root}>
      <List disablePadding>
        {organisations.map(({ id, name }) => {
          return (
            <ListItem
              key={id}
              button
              component={Link}
              to={`/organisations/${id}`}
              divider
            >
              <ListItemText>{name}</ListItemText>
            </ListItem>
          );
        })}
      </List>
      <Button
        variant="outlined"
        color="primary"
        fullWidth
        onClick={deleteAllOrganisation}
      >
        {"Supprimer tout"}
      </Button>
    </div>
  );
}
