import React, { useState, useEffect } from "react";
import { TextField, Button, makeStyles, Typography } from "@material-ui/core";

import {
  getOrganisationById,
  getLinkedTypesByOrganisationId,
} from "../controllers";

import TypeSelector from "../../type/TypeSelector";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.common.whiteBackground,
    "& > *": {
      marginBottom: theme.spacing(1),
    },
  },
}));

export default function EditOrganisationForm({ organisationId }) {
  const classes = useStyles();

  const [name, setName] = useState("");
  const [types, setTypes] = useState([]);
  const [initialTypes, setInitialTypes] = useState([]);

  function validate() {
    return name.length > 0;
  }

  function handleNameChange(e) {
    setName(e.target.value);
  }

  function handleCreateButtonClick() {
    setName("");
  }

  async function _getOrganisation() {
    const organisation = await getOrganisationById(organisationId);
    const linkedTypes = await getLinkedTypesByOrganisationId(organisationId);
    const types = linkedTypes.map(({ type }) => type);
    const { name } = organisation;
    if (name) setName(name);
    if (types) setInitialTypes(types);
  }

  useEffect(() => {
    _getOrganisation();
  }, []);

  return (
    <form className={classes.root}>
      <Typography variant="h6">Modifiez l'organisation</Typography>
      <TextField
        variant="outlined"
        fullWidth
        onChange={handleNameChange}
        value={name}
        label={"Nom"}
      />
      <TypeSelector initialSelection={initialTypes} />
      <Button
        onClick={handleCreateButtonClick}
        fullWidth
        variant="contained"
        color="primary"
        disabled={!validate()}
      >
        Enregistrez les modifications
      </Button>
    </form>
  );
}
