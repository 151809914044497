import React from "react";
import { makeStyles } from "@material-ui/core";

import LoginForm from "../../features/auth/LoginForm";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
}));

export default function Login() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <LoginForm />
    </div>
  );
}
