import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@material-ui/core/styles";
import { blueGrey, blue, grey } from "@material-ui/core/colors";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#002f6c",
    },
    secondary: {
      main: blue[700],
    },
    background: {
      default: blueGrey[100],
    },
    common: {
      border: grey[500],
      whiteBackground: grey[50],
    },
  },
});

export default theme;
