import React from "react";

import { makeStyles } from "@material-ui/core";

import EditOrganisationForm from "../../features/organisation/EditOrganisationForm";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    width: "100%",
  },
}));

export default function Organisation({ match }) {
  const classes = useStyles();
  const { organisationId } = match.params;

  return (
    <div className={classes.root}>
      <EditOrganisationForm organisationId={organisationId} />
    </div>
  );
}
