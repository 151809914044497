import React from "react";
import { Route, Switch } from "react-router-dom";

import Home from "../pages/Home";
import Test from "../pages/Test";
import Login from "../pages/Login";
import Organisations from "../pages/Organisations";
import Organisation from "../pages/Organisation";
import Type from "../pages/Type";
import Profile from "../pages/Profile";
import Maps from "../pages/Maps";

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/test" component={Test} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/organisations" component={Organisations} />
      <Route
        exact
        path="/organisations/:organisationId"
        component={Organisation}
      />
      <Route exact path="/types" component={Type} />
      <Route path="/">
        <Home />
        <Switch>
          <Route exact path="/profile" component={Profile} />
          <Route exact path="/maps" component={Maps} />
        </Switch>
      </Route>
    </Switch>
  );
}
