import { Storage } from "@aws-amplify/storage";
import { DataStore, Predicates } from "@aws-amplify/datastore";
import { v4 as uuid } from "uuid";

import { createFile, getFileById } from "../../data/files-datastore";

import { Map } from "../../models";

export const uploadAttachment = async (objectId, file) => {
  const type = file.type;
  const extension = file.name.split(".").pop();
  const key = `${objectId}.${extension}`;

  Storage.put(key, file, {
    level: "protected",
    contentType: type,
  });
};

export const createMap = async (name, file) => {
  const fileId = uuid();
  const newMap = await DataStore.save(
    new Map({
      name,
      fileId,
    })
  );
  createFile({ id: fileId, file });
  return newMap;
};

export const getAllMaps = async () => {
  try {
    const storedMaps = await DataStore.query(Map);
    const maps = await Promise.all(
      storedMaps.map(async (map) => {
        const file = await getFileById({ id: map.fileId });
        return { ...map, file };
      })
    );
    return maps;
  } catch (error) {
    console.log("error", error);
  }
};

export const deleteAllMaps = async () => {
  try {
    DataStore.delete(Map, Predicates.ALL);
  } catch (error) {
    console.log(error);
  }
};
