import { DataStore, Predicates } from "@aws-amplify/datastore";

import { Organisation, OrganisationType } from "../../models";

export async function createOrganisation({ name, types }) {
  if (name) {
    try {
      const organisation = await DataStore.save(new Organisation({ name }));
      await Promise.all(
        types.map(async (type) => {
          await addTypeToOrganisation(type, organisation);
        })
      );
    } catch (error) {
      console.log("error", error);
    }
  }
}

export async function addTypeToOrganisation(type, organisation) {
  await DataStore.save(new OrganisationType({ organisation, type }));
}

export async function getAllOrganisations() {
  try {
    const organisations = await DataStore.query(Organisation);
    return organisations;
  } catch (error) {
    console.log("error", error);
  }
}

export async function deleteAllOrganisation() {
  try {
    DataStore.delete(Organisation, Predicates.ALL);
  } catch (error) {
    console.log("error", error);
  }
}

export function observeOrganisation(callback) {
  return DataStore.observe(Organisation).subscribe(callback);
}

export async function getOrganisationById(id) {
  try {
    const organisation = await DataStore.query(Organisation, id);
    return organisation;
  } catch (error) {
    console.log(error);
  }
}

export async function getLinkedTypesByOrganisationId(organisationId) {
  const types = (await DataStore.query(OrganisationType)).filter(
    (ot) => ot.organisation.id === organisationId
  );
  return types;
}
