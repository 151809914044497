import React, { useState } from "react";
import { TextField, Button, makeStyles, Typography } from "@material-ui/core";

import TypeSelector from "../../type/TypeSelector";

import { createOrganisation } from "../controllers";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.common.whiteBackground,
    "& > *": {
      marginBottom: theme.spacing(1),
    },
  },
}));

export default function CreateOrganisationForm() {
  const classes = useStyles();

  const [name, setName] = useState("");
  const [types, setTypes] = useState([]);

  function validate() {
    return name.length > 0;
  }

  function handleNameChange(e) {
    setName(e.target.value);
  }

  function handleTypeChange(types) {
    setTypes(types);
  }

  function handleCreateButtonClick() {
    createOrganisation({ name, types });
    setName("");
  }
  return (
    <form className={classes.root}>
      <Typography variant="h6">Créez une organisation</Typography>
      <TextField
        variant="outlined"
        fullWidth
        onChange={handleNameChange}
        value={name}
        label={"Nom"}
      />
      <TypeSelector onChange={handleTypeChange} />
      <Button
        onClick={handleCreateButtonClick}
        fullWidth
        variant="contained"
        color="primary"
        disabled={!validate()}
      >
        Créer l'organisation
      </Button>
    </form>
  );
}
