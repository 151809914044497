import React from "react";
import { makeStyles, Grid, Paper } from "@material-ui/core";

import Header from "../../containers/Header";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  editor: {
    background: "white",
  },
}));

export default function DesktopLayout() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Header />
      <Grid container spacing={1}>
        <Grid item md={3}>
          list
        </Grid>
        <Grid item md={3}>
          <Paper className={classes.editor} elevation={3}>
            items
          </Paper>
        </Grid>
        <Grid item md={6}>
          <Paper className={classes.editor} elevation={3}>
            Editor
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
