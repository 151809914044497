import { DataStore, Predicates } from "@aws-amplify/datastore";

import { Type } from "../../models";

export async function createType({ name }) {
  if (name) {
    try {
      await DataStore.save(
        new Type({
          name,
        })
      );
    } catch (error) {
      console.log("error", error);
    }
  }
}

export async function getAllTypes() {
  try {
    const Types = await DataStore.query(Type);
    return Types;
  } catch (error) {
    console.log("error", error);
  }
}

export async function deleteAllType() {
  try {
    DataStore.delete(Type, Predicates.ALL);
  } catch (error) {
    console.log("error", error);
  }
}

export function observeType(callback) {
  return DataStore.observe(Type).subscribe(callback);
}
