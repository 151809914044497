import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";

import { makeStyles, Avatar, Button } from "@material-ui/core";

import ProfileDialog from "./ProfileDialog";

import { AppContext } from "../../../app/App";

const useStyles = makeStyles((theme) => ({
  avatar: {
    //backgroundColor: theme.palette.primary.main,
    width: 36,
    height: 36,
  },
}));

export default function ProfileButton() {
  const classes = useStyles();

  const [dialogOpen, setDialogOpen] = useState(false);

  const { currentAuthenticatedUser, isAuthenticated } = useContext(AppContext);

  const initials = currentAuthenticatedUser?.charAt(0).toUpperCase();

  function closeDialog() {
    setDialogOpen(false);
  }
  function openDialog() {
    setDialogOpen(true);
  }

  const authenticatedButton = (
    <>
      <Avatar className={classes.avatar} onClick={openDialog}>
        {initials}
      </Avatar>
      <ProfileDialog
        onClose={closeDialog}
        open={dialogOpen}
        currentAuthenticatedUser={currentAuthenticatedUser}
      />
    </>
  );

  const nonAuthenticatedButton = (
    <Button
      disableElevation
      variant="contained"
      color="primary"
      component={Link}
      to={"/login"}
    >
      Se connecter
    </Button>
  );

  return <>{isAuthenticated ? authenticatedButton : nonAuthenticatedButton}</>;
}
