import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { Person, Map, List } from "@material-ui/icons";
import { BottomNavigation, BottomNavigationAction } from "@material-ui/core";
import LoginBanner from "./components/LoginBanner";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: "fixed",
    bottom: 0,
    zIndex: 500,
    background: theme.palette.common.whiteBackground,
    borderTop: `solid 1px ${theme.palette.common.border}`,
  },
}));

export default function MobileLayout() {
  const classes = useStyles();
  const [value, setValue] = React.useState("maps");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
      <LoginBanner />
      <BottomNavigation
        value={value}
        onChange={handleChange}
        className={classes.root}
      >
        <BottomNavigationAction
          label="Plans"
          value="maps"
          icon={<Map />}
          component={Link}
          to={"/maps"}
        />
        <BottomNavigationAction
          component={Link}
          to={"/lists"}
          label="Listes"
          value="lists"
          icon={<List />}
        />
        <BottomNavigationAction
          component={Link}
          to={"/profile"}
          label="Vous"
          value="user"
          icon={<Person />}
        />
      </BottomNavigation>
    </div>
  );
}
