// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Type, OrganisationType, Organisation, OrganisationUser, User, List, Observation, Map } = initSchema(schema);

export {
  Type,
  OrganisationType,
  Organisation,
  OrganisationUser,
  User,
  List,
  Observation,
  Map
};