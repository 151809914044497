import React from "react";

import { makeStyles } from "@material-ui/core";

import CreateTypeForm from "../../features/type/CreateTypeForm";
import TypesCollection from "../../features/type/TypesCollection";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    width: "100%",
  },
}));

export default function Type() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <TypesCollection />
      <CreateTypeForm />
    </div>
  );
}
