import React, { useContext } from "react";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";

import { AppContext } from "../../../app/App";

const useStyles = makeStyles((theme) => ({
  root: {},
  buttonBanner: {
    display: "flex",
    justifyContent: "flex-end",
    padding: theme.spacing(1),
    background: theme.palette.primary.main,
    position: "fixed",
    top: 0,
    width: "100%",
  },
  topMargin: {
    height: theme.spacing(7),
  },
}));

export default function LoginBanner() {
  const classes = useStyles();
  const { isAuthenticated } = useContext(AppContext);

  return (
    <div className={classes.root}>
      {!isAuthenticated && (
        <>
          <div className={classes.buttonBanner}>
            <Button
              component={Link}
              to="/login"
              variant="contained"
              color="secondary"
              disableElevation
            >
              Se connecter
            </Button>
          </div>
          <div className={classes.topMargin} />
        </>
      )}
    </div>
  );
}
