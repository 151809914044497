import React, { useState, useContext } from "react";
import {
  Typography,
  TextField,
  Button,
  Divider,
  makeStyles,
} from "@material-ui/core";

import { Auth } from "@aws-amplify/auth";

import GoogleIcon from "./GoogleIcon";

import { AppContext } from "../../../app/App";

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: "border-box",
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    border: `solid 1px ${theme.palette.common.border}`,
    backgroundColor: theme.palette.common.whiteBackground,
    "& > *": {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
  },
  submitContainer: {
    display: "flex",
    marginTop: theme.spacing(2),
  },
  secondaryButton: {
    textTransform: "none",
    color: theme.palette.primary.main,
    flexGrow: "1",
  },
}));

export default function LoginForm() {
  const classes = useStyles();

  const [email, setEmail] = useState("");
  const [pwd, setPwd] = useState("");
  const { setIsAuthenticated } = useContext(AppContext);

  function validate() {
    return (email.length > 0) & (pwd.length > 0);
  }

  function handleEmailChange(e) {
    const email = e.target.value;
    setEmail(email);
  }

  function handlePwdChange(e) {
    const pwd = e.target.value;
    setPwd(pwd);
  }

  function handleGoogleSSO() {
    Auth.federatedSignIn({ provider: "Google" });
  }

  async function handleSubmit() {
    try {
      await Auth.signIn(email, pwd);
      setIsAuthenticated(true);
      console.log("logged in");
      const user = await Auth.currentAuthenticatedUser();
      console.log("user", user);
    } catch (error) {
      console.log("error: ", error);
    }
  }

  return (
    <form className={classes.root}>
      <Typography> Connectez-vous à votre compte</Typography>
      <TextField
        label="email"
        variant="outlined"
        value={email}
        onChange={handleEmailChange}
        fullWidth
      />
      <TextField
        label="mot de passe"
        variant="outlined"
        value={pwd}
        onChange={handlePwdChange}
        fullWidth
      />
      <Divider />

      <Button
        onClick={handleGoogleSSO}
        fullWidth
        variant="outlined"
        startIcon={<GoogleIcon />}
      >
        Continuez avec Google
      </Button>
      <div className={classes.submitContainer}>
        <Button classes={{ root: classes.secondaryButton }}>
          Créez un compte
        </Button>
        <Button
          disabled={!validate()}
          disableElevation
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        >
          Se connecter
        </Button>
      </div>
    </form>
  );
}
