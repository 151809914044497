import React from "react";
import { useMediaQuery } from "@material-ui/core";

import ProfileML from "./ProfileML";
import ProfileDL from "./ProfileDL";

export default function Profile() {
  const match = useMediaQuery((theme) => theme.breakpoints.up("md"));
  return <div>{match ? <ProfileDL /> : <ProfileML />}</div>;
}
