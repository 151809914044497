import React, { useState } from "react";
import { TextField, Button, makeStyles, Typography } from "@material-ui/core";

import { createType } from "../controllers";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.common.whiteBackground,
    "& > *": {
      marginBottom: theme.spacing(1),
    },
  },
}));

export default function CreateTypeForm() {
  const classes = useStyles();

  const [name, setName] = useState("");

  function validate() {
    return name.length > 0;
  }

  function handleNameChange(e) {
    setName(e.target.value);
  }

  function handleCreateButtonClick() {
    createType({ name });
    setName("");
  }
  return (
    <form className={classes.root}>
      <Typography variant="h6">Créez un type</Typography>
      <TextField
        variant="outlined"
        fullWidth
        onChange={handleNameChange}
        value={name}
        label={"Nom"}
      />
      <Button
        onClick={handleCreateButtonClick}
        fullWidth
        variant="contained"
        color="primary"
        disabled={!validate()}
      >
        Créer le type
      </Button>
    </form>
  );
}
