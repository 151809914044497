import React, { useState, useEffect } from "react";
import { makeStyles, Typography, Button } from "@material-ui/core";

import FileImage from "../FileImage";

import { getAllMaps, deleteAllMaps } from "../controllers";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: theme.spacing(1),
    display: "flex",
    justifyContent: "space-around",
  },
  card: {
    width: "40vw",
    height: "40vw",
    display: "flex",
    flexDirection: "column",
  },
  imageContainer: {
    height: "80%",
    marginBottom: theme.spacing(1),
  },
  name: {
    textAlign: "center",
  },
}));

function Card({ file, name }) {
  const classes = useStyles();
  return (
    <div className={classes.card}>
      <div className={classes.imageContainer}>
        <FileImage file={file} />
      </div>

      <Typography noWrap className={classes.name} variant="caption">
        {name}
      </Typography>
    </div>
  );
}

export default function MapsGrid() {
  const classes = useStyles();

  const [maps, setMaps] = useState([]);

  async function onLoad() {
    const maps = await getAllMaps();
    if (maps) setMaps(maps);
  }
  useEffect(() => {
    onLoad();
  }, [maps.length]);

  function handleDeleteButtonClick() {
    setMaps([]);
    deleteAllMaps();
  }
  return (
    <>
      <div className={classes.root}>
        {maps?.map((map) => (
          <Card key={map.id} {...map} />
        ))}
      </div>
      <Button
        variant="outlined"
        color="primary"
        fullWidth
        onClick={handleDeleteButtonClick}
      >
        {"Supprimer tout"}
      </Button>
    </>
  );
}
