import React from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  makeStyles,
} from "@material-ui/core";

import { Auth } from "@aws-amplify/auth";

const useStyles = makeStyles((theme) => ({
  button: {},
}));

export default function ProfileDialog({
  onClose,
  open,
  currentAuthenticatedUser,
}) {
  const classes = useStyles();

  function handleClose() {
    onClose();
  }

  async function handleLogoutButtonClick() {
    await _signOut();
    onClose();
  }

  async function _signOut() {
    try {
      await Auth.signOut();
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }
  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogContent dividers>
        <Typography>{currentAuthenticatedUser}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          disableElevation
          variant="contained"
          className={classes.button}
          onClick={handleLogoutButtonClick}
        >
          Se déconnecter
        </Button>
      </DialogActions>
    </Dialog>
  );
}
